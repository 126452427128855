import axios from "axios";
// import APP_URL from './common/constants';

const URL = window.location.href.includes("www") ? "www." : "";

console.log("URL", URL);

// - API Link for DEV server
const APP_URL = "http://dev.vschoolindia.com/vopa/api/";
const SEPARATE_URL = "http://dev.vschoolindia.com/content/api/";

// - API Link for PROD BUILD
//  'https://vschoolindia.com/vopa/api/';
// const APP_URL = `https://${URL}vschoolindia.com/vopa/api/`;
// const SEPARATE_URL = `https://${URL}vschoolindia.com/content/api/`;

// - API Link for Local server
// const APP_URL = 'https://lessons.vopa.in/vopa/api/';

// const config = {
//     headers: {
//       Accept: 'application/json',
//         'Content-Type': 'application/json',
//         'X-Requested-With': 'XMLHttpRequest'
//     }
//   };

//   headers: new Headers({
//     'content-type': 'application/json',
//     'X-Requested-With': 'XMLHttpRequest',
//   }),

const http = axios.create({
  baseURL: APP_URL,
});

export const httpV2 = axios.create({
  baseURL: SEPARATE_URL,
});

// export const httpTts = axios.create({
//   // baseURL: 'http://13.127.57.121:8001/',
//   baseURL: 'http://dev.vschoolindia.com/testTts/', //For Devz
// //   baseURL: `https://${URL}vschoolindia.com/testTts/`, //For PROD
//   // baseURL: 'http://127.0.0.1:8002/',
// });

export default http;
